body {
  text-align: center;
  font-size: 22.5vw;
  background: #000;
}

.App {
  cursor: pointer;
  user-select: none;
}

.time {
  display: grid;
  grid-template-columns: 79.5vw 19.5vw;
  grid-template-rows: 1fr 1fr;
  gap: 1px 1px;
  grid-template-areas: "time ampm" "time seconds";
  font-family: 'Orbitron';
  color: #0F0;
  margin-top: calc(50vh - 1.25ch);
}

.time .hour-minutes {
  grid-area: time;
  text-align: left;
  padding: 0 4vw 0 0;
}

.time .seconds {
  grid-area: seconds;
  padding-bottom: 20px;
}

.time .ampm {
  grid-area: ampm;
  padding-top: 4vw;
}

.time .small {
  font-size: 10vw;
  line-height: 9vw;
  font-size: 11vw;
}

.time .small .digit {
  display: inline-block;
  width: 9.75vw;
}

.today {
  color: #00b200;
  font-family: 'Orbitron';
  font-size: 7vw;
  width: 100vw;
}

.today svg {
  width: 97.5vw;
}
